import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matAppsOutline,
  matBusinessOutline,
  matCheckOutline,
  matDevicesOutline,
  matLocalPoliceOutline,
  matLockOutline,
  matPowerSettingsNewOutline,
  matReceiptLongOutline,
  matSettingsOutline,
  matTransformOutline,
  matUnfoldMoreOutline,
} from '@ng-icons/material-icons/outline';

import { Store } from '@app/config/store';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from '@app/pages/auth/change-password/change-password.component';

enum Apps {
  Einvois = 'taxpayer',
  Garnet = 'garnet',
  Settings = 'account',
}

@Component({
  selector: 'app-header, [app-header]',
  standalone: true,
  imports: [RouterModule, NgIconComponent, NgbDropdownModule, ChangePasswordComponent],
  providers: [
    provideIcons({
      matBusinessOutline,
      matSettingsOutline,
      matAppsOutline,
      matUnfoldMoreOutline,
      matPowerSettingsNewOutline,
      matTransformOutline,
      matReceiptLongOutline,
      matLocalPoliceOutline,
      matDevicesOutline,
      matCheckOutline,
      matLockOutline,
    }),
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent {
  @ViewChild('menu') menu!: ElementRef;

  Apps = Apps;
  activeApp: Apps = Apps.Einvois;
  orgName = 'Kevin Motors';

  accounts = [
    {
      id: '1',
      name: 'Kevin Motors',
      role: 'Admin',
    },
    {
      name: 'Metalbooks Pvt Ltd',
      id: '2',
      role: 'Manager',
    },
  ];

  constructor(
    protected store: Store,
    private ngbModal: NgbModal,
    private route: ActivatedRoute,
  ) {
    this.route.url.subscribe((url) => {
      this.activeApp = url[0].path as Apps;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openModal(content: any, size = 'sm') {
    this.ngbModal.open(content, { centered: true, size });
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  switchAccount(accountId: number | string) {
    if (accountId === this.store.activeAccount()?.orgId) return;

    this.ngbModal.dismissAll();
    this.store.switchAccount(accountId);
  }
}
