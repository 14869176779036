import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@app/config/store';
import { email } from '@app/dto/common.dto';
import { AuthService } from '@app/services/auth.service';
import { RecaptchaService } from '@app/services/recaptcha.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { matRemoveRedEye, matVisibilityOff } from '@ng-icons/material-icons/baseline';
import { matDeleteOutlineOutline } from '@ng-icons/material-icons/outline';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule, NgIconComponent],
  providers: [provideIcons({ matDeleteOutlineOutline, matRemoveRedEye, matVisibilityOff })],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent {
  redirectURL: string = '';
  passwordFieldType: string = 'password';

  passwordExpired: boolean = false;
  loginForm: FormGroup = new FormGroup({
    userId: new FormControl('', [email]),
    password: new FormControl('', []),
    token: new FormControl('', []),
  });

  constructor(
    private router: Router,
    protected store: Store,
    private authSvc: AuthService,
    private recaptcha: RecaptchaService,
  ) {
    const redirectUrl = this.router.parseUrl(this.router.url).queryParams['redirectUrl'];

    if (redirectUrl && redirectUrl !== '') sessionStorage.setItem('redirectUrl', redirectUrl);

    this.redirectURL = sessionStorage.getItem('redirectUrl') ?? '';
    sessionStorage.removeItem('redirectUrl');
  }

  login(): void {
    const payload = this.loginForm.value;
    this.authSvc.login(payload).subscribe((resp) => {
      if (resp) {
        if (resp.passwordExpired) {
          this.passwordExpired = true;
          return;
        }

        this.store.setTaxpayer(resp);
        const query = this.redirectURL !== '' ? { redirectUrl: this.redirectURL } : {};
        this.router.navigate(['/redirect'], { queryParams: query });
      }
    });
  }

  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  verifyCaptcha() {
    this.recaptcha.execute('login').then((token) => {
      if (token) {
        this.loginForm.patchValue({ token });
        this.login();
      }
    });
  }

  forgetPassword() {
    this.router.navigate(['/forget-password'], {
      queryParams: { email: this.loginForm.value.userId },
    });
  }
}
